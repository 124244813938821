// Generated by Framer (25dafa9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["r_TtPkly4", "iBGap_qoQ", "s4jsrZqBJ"];

const variantClassNames = {iBGap_qoQ: "framer-v-1iip5kl", r_TtPkly4: "framer-v-1otxjsb", s4jsrZqBJ: "framer-v-220kac"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "r_TtPkly4", "Tab 2": "iBGap_qoQ", "Tab 3": "s4jsrZqBJ"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "r_TtPkly4", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "r_TtPkly4", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tapp5awhf = activeVariantCallback(async (...args) => {
setVariant("r_TtPkly4")
})

const tap1p59wr1 = activeVariantCallback(async (...args) => {
setVariant("iBGap_qoQ")
})

const tap4af31v = activeVariantCallback(async (...args) => {
setVariant("s4jsrZqBJ")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-eKch5", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1otxjsb", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"r_TtPkly4"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({iBGap_qoQ: {"data-framer-name": "Tab 2"}, s4jsrZqBJ: {"data-framer-name": "Tab 3"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ppgch4-container"} layoutDependency={layoutDependency} layoutId={"WSYqXdABu-container"} transition={transition}><Tab height={"100%"} id={"WSYqXdABu"} layoutId={"WSYqXdABu"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({iBGap_qoQ: {tap: tapp5awhf, variant: "ym0P2kopY"}, s4jsrZqBJ: {tap: tapp5awhf, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-sxqi69-container"} layoutDependency={layoutDependency} layoutId={"SWJeuMWTC-container"} transition={transition}><Tab height={"100%"} id={"SWJeuMWTC"} layoutId={"SWJeuMWTC"} tap={tap1p59wr1} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({iBGap_qoQ: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-1j3xq9k-container"} layoutDependency={layoutDependency} layoutId={"eI9uogepH-container"} transition={transition}><Tab height={"100%"} id={"eI9uogepH"} layoutId={"eI9uogepH"} tap={tap4af31v} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({s4jsrZqBJ: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-eKch5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eKch5 .framer-1ofzqay { display: block; }", ".framer-eKch5 .framer-1otxjsb { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-eKch5 .framer-1ppgch4-container, .framer-eKch5 .framer-sxqi69-container, .framer-eKch5 .framer-1j3xq9k-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eKch5 .framer-1otxjsb { gap: 0px; } .framer-eKch5 .framer-1otxjsb > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-eKch5 .framer-1otxjsb > :first-child { margin-left: 0px; } .framer-eKch5 .framer-1otxjsb > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"iBGap_qoQ":{"layout":["auto","auto"]},"s4jsrZqBJ":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const FramernR2MSwkIZ: React.ComponentType<Props> = withCSS(Component, css, "framer-eKch5") as typeof Component;
export default FramernR2MSwkIZ;

FramernR2MSwkIZ.displayName = "Elements/Tabs";

FramernR2MSwkIZ.defaultProps = {height: 200, width: 200};

addPropertyControls(FramernR2MSwkIZ, {variant: {options: ["r_TtPkly4", "iBGap_qoQ", "s4jsrZqBJ"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(FramernR2MSwkIZ, [...TabFonts])